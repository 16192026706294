import {APIService} from "../../services/api";
import i18n from "@/plugins/i18n";

export const statusActions = [
  {
    type: 'activate',
    label: '',
    class: 'fa fa-check',
    action: {
      type: 'callback',
      callbackFunction: async (_list, data, actionsParams, refresh, eventBus) => {
        data.status = 0;
        try {
          await APIService.patch(APIService.getRestFullEntityUrl(actionsParams.url, data), data);
          eventBus.$emit('alert', {
            type: 'success',
            text: i18n.t('statusWasSuccessfullySaved'),
          });
        } catch (e) {
          eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnStatusSaving'),
          });
        }
        refresh();
      },
      visibilityResolver: (data) => {
        return data.status === 1;
      },
    },
  },
  {
    type: 'deactivate',
    label: '',
    class: 'fa fa-ban',
    action: {
      type: 'callback',
      callbackFunction: async (_list, data, actionsParams, refresh, eventBus) => {
        data.status = 1;
        try {
          await APIService.patch(APIService.getRestFullEntityUrl(actionsParams.url, data), data);
          eventBus.$emit('alert', {
            type: 'success',
            text: i18n.t('statusWasSuccessfullySaved'),
          });
        } catch (e) {
          eventBus.$emit('alert', {
            type: 'danger',
            text: i18n.t('errorOnStatusSaving'),
          });
        }
        refresh();
      },
      visibilityResolver: (data) => {
        return data.status === 0;
      },
    },
  },
]
