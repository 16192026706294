<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('SmsTextList') }}</h2>
      <b-row class="mt-3">
        <b-col md="4" lg="3" sm="12">
          <date-picker
            v-model="date"
            :config="dateOptions"
          />
        </b-col>
        <b-col md="4" lg="3" sm="12">
          <b-button
            variant="primary"
            @click="searchOrders"
          >
            {{ $t('search') }}
          </b-button>
        </b-col>
        <b-col md="4" lg="3" offset-lg="3" sm="12">
          <b-button class="w-100 bg-success" variant="link">
            <a class="v-auth-href" v-auth-href :href="exportUrl">
              <span class="fa fa-file-excel-o" /> {{ $t('ExcelExport') }}
            </a>
          </b-button>
        </b-col>
      </b-row>
      <grid-view-table
        v-if="date"
        id="sms-list"
        ref="sms-list"
        :columns="columns"
        :path="'order'"
        :extra-params="gridViewUrl"
        :per-page="100"
        :default-sort="'createdAt,ASC'"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import GridViewTable from '../../components/GridViewTable';
import { columns } from './grid-views/sms';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { APIService } from '../../services/api';

export default {
  name: 'DistributionList',
  components: {
    GridViewTable,
    datePicker,
  },
  data: () => {
    return {
      columns,
      date: null,
      dateOptions: {
        format: 'DD.MM.YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        locale: 'ru',
      }
    }
  },
  async beforeMount() {
    this.date = this.$store.getters.GET_SMS_LIST_DATE;
  },
  methods: {
    calculateDays() {
      const date = this.$store.getters.GET_SMS_LIST_DATE;
      const startDate = moment(date, 'DD.MM.YYYY').startOf('day');
      const endDate = moment(startDate).endOf('day');
      return [startDate.format(), endDate.format()].map(dateTime => encodeURIComponent(dateTime));
    },
    searchOrders() {
      const date = moment(this.date, 'DD.MM.YYYY').toDate();
      this.$store.dispatch('UPDATE_SMS_LIST_DATE', date);
    },
  },
  computed: {
    gridViewUrl() {
      const [startDate, endDate] = this.calculateDays();
      let url = '&join=forms&join=managers&join=statuses&join=drivers&join=paymentMethods&join=deliveryDates';
      url += '&filter=statuses.token||$in||during_delivery,delivered';
      return url + `&filter=deliveryDates.date||$between||${startDate},${endDate}`;
    },
    exportUrl() {
      const [startDate, endDate] = this.calculateDays();
      return `${APIService.getBaseUrl()}export/logistics/sms/${startDate}/${endDate}`;
    }
  },
}
</script>

<style scoped>
  .v-auth-href {
    color: white;
  }
</style>
